import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login } from '../services/auth-api';
import { useSession } from "providers/SessionProvider";

toast.configure({
  position: 'top-right', // Position of toast messages
  autoClose: 5000,           // Auto close time in milliseconds
  hideProgressBar: false,     // Whether to show progress bar
  closeOnClick: true,         // Close toast on click
  pauseOnHover: true,         // Pause timer when hovering over toast
  draggable: true,            // Make toast draggable
  draggablePercent: 60,       // Percentage of toast width draggable
});

const Login = () => {
  const navigate = useNavigate();
  const { updateToken } = useSession();
  const [otp, setOtp] = useState("");
  const [ButtonDisabled, setButtonDisabled] = useState(false);
  const [phone, setPhone] = useState({});
  const [message, setMessage] = useState({
    message: "",
    error: false,
  });

  const handleNumInputsChange = (e) => {
    console.log(e.target.value);
    setOtp(e.target.value);
    setButtonDisabled(false);
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    await login({ mobileNumber: phone, otp: otp }).then((res) => {
      console.log(res);
      updateToken(res.data.token);
      const path = "/";
      navigate(path);
    }).catch((err) => {
      console.log(err)
      toast.error(err.error);
      setMessage({
        message: err.error,
        error: true,
      });
    })
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      {/* <!-- Bootstrap core CSS --> */}
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.0.0/css/bootstrap.min.css"
        rel="stylesheet"
      />
      {/* <!-- Material Design Bootstrap --> */}
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.5.4/css/mdb.min.css"
        rel="stylesheet"
      />
      <style>
        {`
          html, p {
            font-size: 16px;
            font-weight: 300;
          }
          input {
            font-weight: 400;
          }
          .free-bird {
            margin-top: 60px !important;
          }
          .btn-deep-purple {
            background-color: #4285F4 !important;
            color: #fff!important;
          }
          form label {
            display: none;
          }
        `}
      </style>

      <div className="card card-cascade wider reverse">
        <div className="viw view-cascade overlays">
          <a href="#!">
            <div className="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
      <div
        className="row"
        style={{
          marginTop: "0px",
          marginBottom: "0px",
        }}
      >
        <div className="edge-header deep-purple"></div>

        <div className="container free-bird">
          <div className="row login-background">
            <div className="col-md-8 col-lg-7 mx-auto float-none white z-depth-1 py-2 px-2">
              <div className="card-body">
                <h2 className="h2-responsive text-center">
                  <strong>Login Area</strong>
                </h2>
                <p className="pb-4 text-center">Cameroon Reports!</p>
                <form onSubmit={handleButtonClick}>
                  <h5 className="h5-responsive">Your Mobile Number</h5>
                  <div className="md-form">
                    <i className="fa fa-mobile prefix"></i>
                    <input
                      type="number"
                      name="email"
                      id="form9"
                      className="form-control validate"
                      required
                      placeholder=""
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}
                    />
                    <label htmlFor="form9" data-error="wrong" data-success="right">
                      Your Mobile Number
                    </label>
                  </div>
                  <h5 className="h5-responsive">Verify OTP</h5>
                  <div className="md-form">
                    <i className="fa fa-lock prefix"></i>
                    <input
                      value={otp}
                      type="password"
                      name="password"
                      id="form2"
                      className="form-control validate"
                      required
                      onChange={handleNumInputsChange}
                    />
                    <label htmlFor="form2">Your password</label>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      name="submit"
                      className="btn btn-deep-purple waves-effect waves-light"
                      disabled={ButtonDisabled}>
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
